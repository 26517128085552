import { ethers } from 'ethers'
import { SupportedChain } from '../../lib/constants'

const RPC_LIST = {
  [SupportedChain.Ethereum]: [
    'https://eth-mainnet.g.alchemy.com/v2/gcQg7HfeVdRNa--z3c9LqMIkAeWF1gjG',
    'https://eth.llamarpc.com',
    'https://endpoints.omniatech.io/v1/eth/mainnet/public',
    'https://rpc.ankr.com/eth',
    'https://ethereum.publicnode.com',
    'https://1rpc.io/eth',
    'https://rpc.builder0x69.io',
    'https://rpc.mevblocker.io',
    'https://rpc.flashbots.net',
    'https://virginia.rpc.blxrbdn.com',
    'https://uk.rpc.blxrbdn.com',
    'https://singapore.rpc.blxrbdn.com',
    'https://eth.rpc.blxrbdn.com',
    'https://cloudflare-eth.com',
    'https://eth-mainnet.public.blastapi.io',
    'https://api.securerpc.com/v1',
    'https://eth-pokt.nodies.app',
    'https://ethereum.blockpi.network/v1/rpc/public',
    'https://rpc.payload.de',
    'https://api.zmok.io/mainnet/oaen6dy8ff6hju9k',
    'https://eth.api.onfinality.io/public',
    'https://core.gashawk.io/rpc',
    'https://mainnet.eth.cloud.ava.do',
    'https://ethereumnodelight.app.runonflux.io',
    'https://main-light.eth.linkpool.io',
    'https://rpc.eth.gateway.fm',
    'https://eth.meowrpc.com',
    'https://eth.drpc.org',
    'https://mainnet.gateway.tenderly.co',
    'https://gateway.tenderly.co/public/mainnet',
    'https://api.zan.top/node/v1/eth/mainnet/public',
    'https://rpc.notadegen.com/eth',
    'https://eth.merkle.io',
    'https://rpc.lokibuilder.xyz/wallet',
    'https://eth.nodeconnect.org',
    'https://api.mycryptoapi.com/eth',
    'https://rpc.blocknative.com/boost',
    'https://rpc.flashbots.net/fast',
    'https://rpc.mevblocker.io/fast',
    'https://rpc.mevblocker.io/noreverts',
    'https://rpc.mevblocker.io/fullprivacy'
  ],
  [SupportedChain.Goerli]: [
    'https://eth-goerli.g.alchemy.com/v2/8Vpu96KpWOtMuVVU1VpiTaIu6Nu3LtRn',
    'https://rpc.ankr.com/eth_goerli',
    'https://endpoints.omniatech.io/v1/eth/goerli/public',
    'https://eth-goerli.public.blastapi.io',
    'https://goerli.blockpi.network/v1/rpc/public',
    'https://eth-goerli.api.onfinality.io/public',
    'https://rpc.goerli.eth.gateway.fm',
    'https://ethereum-goerli.publicnode.com',
    'https://goerli.gateway.tenderly.co',
    'https://gateway.tenderly.co/public/goerli',
    'https://api.zan.top/node/v1/eth/goerli/public'
  ],
  [SupportedChain.Polygon]: [
    'https://polygon-mainnet.g.alchemy.com/v2/vfGuAfi-XIdXrB0QluxjUZaOC-J9CWaM',
    'https://polygon.gateway.tenderly.co',
    'https://polygon-bor.publicnode.com',
    'https://polygon.llamarpc.com',
    'https://rpc-mainnet.maticvigil.com',
    'https://endpoints.omniatech.io/v1/matic/mainnet/public',
    'https://polygon-rpc.com',
    'https://rpc-mainnet.matic.quiknode.pro',
    'https://polygon-pokt.nodies.app',
    'https://rpc.ankr.com/polygon',
    'https://1rpc.io/matic'
  ],
  [SupportedChain.Mumbai]: [
    'https://polygon-mumbai.g.alchemy.com/v2/vfGuAfi-XIdXrB0QluxjUZaOC-J9CWaM',
    'https://rpc-mumbai.maticvigil.com',
    'https://endpoints.omniatech.io/v1/matic/mumbai/public',
    'https://rpc.ankr.com/polygon_mumbai',
    'https://polygontestapi.terminet.io/rpc',
    'https://polygon-testnet.public.blastapi.io',
    'https://polygon-mumbai.blockpi.network/v1/rpc/public',
    'https://polygon-mumbai-bor.publicnode.com',
    'https://polygon-mumbai-pokt.nodies.app',
    'https://polygon-mumbai.gateway.tenderly.co',
    'https://gateway.tenderly.co/public/polygon-mumbai',
    'https://api.zan.top/node/v1/polygon/mumbai/public',
    'https://polygon-mumbai.api.onfinality.io/public'
  ]
}

const getEthersProvider = (chainId = SupportedChain.Ethereum) => {
  const urlList = RPC_LIST[chainId]
  if (!urlList) throw new Error('Unsupported chainId')

  const stallTimeout = 5 * 1000
  const options = {
    timeout: 30 * 1000,
    throttleLimit: 1
  }
  const quorum = 2

  const providerList = urlList.map(
    url => new ethers.providers.StaticJsonRpcProvider({ url: url, ...options })
  )

  const fallbackProviderParams = []
  for (let i = 0; i < providerList.length; i++) {
    const params = {
      provider: providerList[i],
      priority: i + 1,
      weight: 1,
      stallTimeout
    }
    fallbackProviderParams.push(params)
  }

  const provider = new ethers.providers.FallbackProvider(
    fallbackProviderParams,
    quorum
  )

  return provider
}

export default getEthersProvider
